// extracted by mini-css-extract-plugin
export var articleList = "KitchenList-article-list--hKd-Y";
export var card = "KitchenList-card--ayu8i";
export var checkboxContainer = "KitchenList-checkbox-container--1fRua";
export var checkmark = "KitchenList-checkmark--JEjkd";
export var headingH3 = "KitchenList-heading--h3--ebw6l";
export var listCategory = "KitchenList-list-category--K1DdT";
export var listCircles = "KitchenList-list-circles--T9WBC";
export var listInline = "KitchenList-list-inline--b2wKH";
export var listPlain = "KitchenList-list-plain--Z-Ky6";
export var listSmColumn = "KitchenList-list-sm-column--tCHWP";
export var listTag = "KitchenList-list-tag--yAPqa";
export var recipeImage = "KitchenList-recipeImage--NJqdz";
export var section = "KitchenList-section--JALY4";
export var sectionHeadline = "KitchenList-section-headline--sUKmL";
export var spacingVBLg = "KitchenList-spacing-v-b-lg--nBWdS";
export var spacingVBMd = "KitchenList-spacing-v-b-md--soMw+";
export var spacingVBSm = "KitchenList-spacing-v-b-sm--E1QP9";
export var spacingVBZ = "KitchenList-spacing-v-b-z--3A7bY";
export var spacingVTLg = "KitchenList-spacing-v-t-lg--Akhem";
export var spacingVTMd = "KitchenList-spacing-v-t-md--H7lxR";
export var spacingVTSm = "KitchenList-spacing-v-t-sm--s2lVZ";
export var spacingVTZ = "KitchenList-spacing-v-t-z--pFwV1";
export var textLink = "KitchenList-textLink--AU3mf";
export var uFlex = "KitchenList-u-flex--f1aKn";
export var uLg1of4 = "KitchenList-u-lg-1of4--hIxuM";
export var uLg3of4 = "KitchenList-u-lg-3of4--vczsr";
export var wrapper = "KitchenList-wrapper--SG0Zi";